<script setup>
import ApplicationLogo from '@/Components/ApplicationLogo.vue'
import { Link, usePage } from '@inertiajs/vue3'
import ApplicationLogoDark from '@/Components/ApplicationLogoDark.vue'
import { trans } from 'laravel-vue-i18n'
import dayjs from 'dayjs';

const page = usePage()

const copyright = page.props.organizationProfile?.data?.name ?? 'Codeshaper'
</script>

<template>
  <div class="loginwrapper">
    <div class="lg-inner-column">
      <div class="right-column relative">
        <div class="inner-content h-full flex flex-col bg-white dark:bg-slate-800">
          <div class="max-w-xl md:p-10 p-7 mx-auto w-full h-full flex flex-col justify-center">
            <div class="mobile-logo text-center mb-6 lg:hidden block">
              <Link href="/">
                <ApplicationLogo class="h-20 fill-current text-gray-500 mx-auto hidden lg:block" />
                <ApplicationLogoDark
                  class="h-20 fill-current text-gray-500 mx-auto lg:hidden mb-10"
                />
              </Link>
            </div>
            <div>
              <slot />
            </div>
          </div>
          <div class="text-xs font-normal dark:text-slate-400 z-50 pb-10 text-center">
            {{ $t('Copyright') }} {{ dayjs().year() }} &copy; {{ copyright }}
          </div>
        </div>
      </div>
      <div class="left-column bg-cover bg-no-repeat bg-center bg-slate-900">
        <div class="flex flex-col h-full justify-center">
          <div class="flex-1 flex flex-col justify-center items-center">
            <Link href="/">
              <ApplicationLogo class="h-32 fill-current text-gray-500" />
            </Link>
          </div>
        </div>
  
      </div>
    </div>
  </div>
</template>

<style>
.loginwrapper {
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  min-height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
  flex-basis: 100%;
}
.loginwrapper .lg-inner-column {
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow-y: auto;
}

.loginwrapper .left-column {
  display: none;
  flex: 1 1 0;
  overflow: hidden;
  --tw-bg-opacity: 1;
}

.loginwrapper .right-column {
  flex: 1 1 0;
}

@media (min-width: 1024px) {
  .loginwrapper .left-column {
    display: block;
  }
}
</style>
