<script setup>
import defaultLogo from '@/Assets/whitelogo.png'
import { usePage } from '@inertiajs/vue3'

const logo = usePage().props.organizationProfile?.data?.logo ?? defaultLogo
const name = usePage().props.organizationProfile?.data?.name ?? 'Codeshaper'
</script>
<template>
  <img :src="logo" :alt="name" class="mx-auto"/>
</template>
